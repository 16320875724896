<template>
  <div>
    <div class="row mb-4">
      <div class="col-lg-6 col-md-12 col-xs-12">
        <b-card class="mb-3" title="Profile">
          <form @submit.prevent="saveProfile">
            <div v-if="usernameAttribute=='USERNAME'" class="form-group">
              <label for="username">Username</label>
              <b-form-input id="username" v-model="user.username" :disabled="true" :state="(errors.username?false:null)" type="text" placeholder="Username" />
            </div>
            <div class="form-group">
              <label for="email">E-mail <span v-if="usernameAttribute=='EMAIL'">(username)</span></label>
              <b-form-input id="email" v-model="user.email" :disabled="usernameAttribute=='EMAIL'" :state="(errors.email?false:null)" type="text" placeholder="E-mail" />
            </div>
            <div class="form-group">
              <label for="phone">Phone <span v-if="usernameAttribute=='PHONE'">(username)</span></label>
              <b-form-input id="phone" v-model="user.phone" :disabled="usernameAttribute=='PHONE'" :state="(errors.phone?false:null)" type="text" placeholder="Phone" />
            </div>
            <div class="form-group">
              <label for="firstname">First Name</label>
              <b-form-input id="firstname" v-model="user.firstname" :state="(errors.firstname?false:null)" type="text" placeholder="First Name" />
            </div>
            <div class="form-group">
              <label for="lastname">Last Name</label>
              <b-form-input id="lastname" v-model="user.lastname" :state="(errors.lastname?false:null)" type="text" placeholder="Last Name" />
            </div>
            <div class="text-center">
              <b-button type="submit" variant="success">
                Save
              </b-button>
            </div>
          </form>
        </b-card>
      </div>
      <div class="col-lg-6 col-md-12 col-xs-12">
        <b-card title="Change password">
          <form @submit.prevent="savePassword">
            <input v-show="false" type="text" autocomplete="username" name="username" :value="user.username">
            <div class="form-group">
              <label for="currentpassword">Current Password</label>
              <b-form-input id="currentpassword" v-model="password.currentpassword" autocomplete="current-password" :state="(errors.currentpassword?false:null)" type="password" placeholder="Current Passoword" />
            </div>
            <div class="form-group">
              <label for="newpassword">New Password</label>
              <b-form-input id="newpassword" v-model="password.newpassword" autocomplete="new-password" :state="(errors.newpassword?false:null)" type="password" placeholder="New Passoword" />
            </div>
            <div class="form-group">
              <label for="newpassword2">Password confirmation</label>
              <b-form-input id="newpassword2" v-model="password.newpassword2" autocomplete="new-password" :state="(errors.newpassword2?false:null)" type="password" placeholder="Password confirmation" />
            </div>
            <div class="text-center">
              <b-button type="submit" variant="success">
                Change
              </b-button>
            </div>
          </form>
        </b-card><br>
        <b-card>
          <div class="text-center">
            <b-button type="button" variant="danger" @click="globalLogut()">
              Logout from all devices
            </b-button>
          </div>
        </b-card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-card title="Latest events">
          <div class="table-responsive">
            <b-table striped hover :fields="authFields" :items="authEvents">
              <template v-slot:cell(CreationDate)="{ item }">
                <span>{{ item.CreationDate | moment('lll') }}</span>
              </template>
              <template v-slot:cell(EventContextData)="{ item }">
                <strong>{{ item.EventContextData.IpAddress }}</strong> <br>
                <span>{{ item.EventContextData.DeviceName }}</span> <br>
                <span>{{ item.EventContextData.City }}, {{ item.EventContextData.Country }}</span>
              </template>
            </b-table>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import Amplify from '../../../services/Amplify';

export default {
  data () {
    return {
      authEvents: [],
      authFields: [
        {
          key: 'CreationDate',
        },
        {
          key: 'EventType',
        },
        {
          key: 'EventResponse',
        },
        {
          key: 'EventContextData',
        },
      ],
      password: {
        currentpassword: '',
        newpassword: '',
        newpassword2: '',
      },
      user: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        username: '',
      },
      errors: {},
    };
  },
  computed: {
    usernameAttribute () {
      return this.$store.getters.org ? this.$store.getters.org.cognitoUsernameAttribute : 'USERNAME';
    },
  },
  created () {
    this.getMe().then(this.getmyAuthEvents);
  },
  methods: {
    globalLogut () {
      this.$bvModal.msgBoxConfirm('Are you sure?', {
        size: 'sm',
        okVariant: 'danger',
      }).then((val) => {
        if (!val) {
          return;
        }
        Amplify.signOut(true)
          .then(() => {
            this.$router.push('/auth/login');
          })
          .catch(this.err);
      });
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    success (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'success',
      });
    },
    getMe () {
      return this.$http.get('users/me')
        .then((res) => {
          this.user = res.data;
        })
        .catch(this.err);
    },
    getmyAuthEvents () {
      return this.$http.get('users/me/authevents')
        .then((res) => {
          this.authEvents = res.data;
        })
        .catch(this.err);
    },
    savePassword () {
      if (this.password.currentpassword === '') {
        this.errors = {};
        this.errors.currentpassword = 'Invalid password';
        return this.error('Error', 'You must enter current password');
      }
      if (this.password.newpassword === '') {
        this.errors = {};
        this.errors.newpassword = 'Invalid password';
        return this.error('Error', 'You must enter new password');
      }
      if (this.password.newpassword !== this.password.newpassword2) {
        this.errors = {};
        this.errors.newpassword = 'Invalid password';
        this.errors.newpassword2 = 'Invalid password';
        return this.error('Error', 'New password must match password confirmation.');
      }
      const loader = this.$loading.show();
      return Amplify.changePassword(this.password.currentpassword, this.password.newpassword)
        .then((data) => { // eslint-disable-line
          loader.hide();
          this.errors = {};
          this.password.currentpassword = '';
          this.password.newpassword = '';
          this.password.newpassword2 = '';
          this.success('Done', 'Password changed');
        })
        .catch((err) => {
          loader.hide();
          this.errors = {};
          if (err.code === 'NotAuthorizedException') {
            this.errors.currentpassword = 'Invalid password';
          }
          if (err.code === 'InvalidParameterException') {
            if (err.message.match(/proposedPassword/g)) {
              this.errors.newpassword = 'Invalid password';
              this.errors.newpassword2 = 'Invalid password';
            }
          }
          if (err.code === 'InvalidPasswordException') {
            this.errors.newpassword = 'Invalid password';
            this.errors.newpassword2 = 'Invalid password';
          }
          this.error('Error', err.message);
        });
    },
    saveProfile () {
      this.$http.patch('users/me', this.user)
        .then((res) => { // eslint-disable-line
          this.errors = {};
          this.success('Done', 'Profile saved');
          this.$store.dispatch('FETCH_ME');
          return this.getMe();
        })
        .catch(this.err);
    },
  },
};
</script>
